import '../../styles/pages/spring-summer-2022/vision-and-visionaries.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2022-stories/more-stories"
// import Link from "gatsby-plugin-transition-link/AniLink";
// import { Parallax, Background } from 'react-parallax';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import socialBanner from "../../images/spring-summer-2022/social-banners/vision-and-visionaries.jpg";


import heroImage from "../../images/spring-summer-2022/vision-and-visionaries/hero.jpg";
import award from "../../images/spring-summer-2022/vision-and-visionaries/award.jpg";


import markCollins from "../../images/spring-summer-2022/vision-and-visionaries/mark-collins.jpg"
import julieMillerPhipps from "../../images/spring-summer-2022/vision-and-visionaries/julie-miller-phipps.jpg"
import darrellJodoin from "../../images/spring-summer-2022/vision-and-visionaries/darrell-jodoin.jpg"
import jeffreyVanHarte from "../../images/spring-summer-2022/vision-and-visionaries/jeffrey-van-harte.jpg"
import vickiVasques from "../../images/spring-summer-2022/vision-and-visionaries/vicki-vasques.jpg"
import lucyDunn from "../../images/spring-summer-2022/vision-and-visionaries/lucy-dunn.jpg"
import bobbyMcdonald from "../../images/spring-summer-2022/vision-and-visionaries/bobby-mcdonald.jpg"
import marcStein from "../../images/spring-summer-2022/vision-and-visionaries/marc-stein.jpg"
import julieGreiner from "../../images/spring-summer-2022/vision-and-visionaries/julie-greiner.jpg"
import anthonyRendon from "../../images/spring-summer-2022/vision-and-visionaries/anthony-rendon.jpg"
import terryDickens from "../../images/spring-summer-2022/vision-and-visionaries/terry-dickens.jpg"


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Vision & Visionaries Recipients Share Memories of CSUF';
    var pageDesc = 'Cal State Fullerton awarded its highest honors during the 25th Vision & Visionaries event honoring four alumni and an honorary alumna.';
    var slug = 'vision-and-visionaries';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${heroImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '80vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <aside className='related-stories align-outside'>
            <h2>A Look Back at Vision & Visionaries</h2>
            
              <img src={award} alt="Vision & Visionaries award" />
            
            <p>“Vision & Visionaries is truly the highest honor that one can receive from Cal State Fullerton,” says Stefanie Light, chief of operations for university advancement, who has been involved in planning almost all of the “V&V” events throughout the years. “This honor focuses on those who have made a difference at the university and/or achieved significant success in their respective fields of work or community support.”</p>
            
            <p>Initially envisioned as a way to honor prominent alumni in the arts, the award was quickly adapted to embrace alumni and community supporters from all areas of the university. Among the first recipients were H. William Bridgford, chairman of Bridgford Foods; Jerry Goodwin, whose family name graces the Titan baseball stadium; and Linda Woolverton, who wrote the screenplay for Disney’s “Beauty and the Beast” and numerous other Disney films.</p>
          </aside>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>The <a href="https://news.fullerton.edu/2022/03/vision-and-visionaries-2022/" target="_blank" rel="noreferrer">2022 honorees</a> are distinguished alumni <strong>Michael Groff</strong> ’78, president and CEO, Toyota Financial Services, retired; <strong>Douglas Stewart</strong> ’87, chairman, Marts & Lundy Inc.; and <strong>Dan Young</strong> ’74, president, Camino Enterprises. Honorary alumna <strong>Ingrid Otero-Smart</strong>, president and CEO, Casanova/McCann; and recent graduate <strong>Lauren DuCharme</strong> ’15, who works in NASA’s Jet Propulsion Laboratory, were also recognized.</p>
 
          <p>The honorees join scores of Titans and advocates of Cal State Fullerton who have been honored with this recognition over the years. Here is a sampling of recollections from previous recipients of the honor.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2001 Honoree</span>
          <h2>Mark Collins Sr.</h2>
          <span className='title'>Owner, 2Fivesports LLC (sports recruiting service)</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownRight">
          <figure className='align-left large'>
            <img src={markCollins} alt="Mark Collins" />
          </figure>
          </ScrollAnimation>
            
          <p style={{clear: 'left'}}>“My education in communications helped me explore all aspects of the field — TV, radio and print. The in-studio work was very helpful. I stay connected with CSUF because I truly believe in Titan pride. Our football team had a saying, ‘Titan Pride, Titan Family.’ That’s still flourishing at the university today.”</p>

          <hr />
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2000 Honoree</span>
          <h2>Terry Dickens ’67</h2>
          <span className='title'>President and CEO, Bixby Land Company (retired)</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-right align-outside large'>
            <img src={terryDickens} alt="Terry Dickens" />
          </figure>
          </ScrollAnimation>
          
          <p>“I graduated from CSUF in 1967. I received an excellent education. My major was business administration and economics, with a specialty in marketing. The excellent professors and their teaching helped me in so many ways as I moved along through my career.</p>

          <p>“Because I received so much from CSUF, I decided to involve myself in its activities. I served on several real estate advisory committees and then taught as an adjunct instructor in the finance department. I taught at L.A. City College, Cerritos College and Fullerton College prior to teaching at Cal State Fullerton. For more than 20 years, I taught one to three classes per semester at CSUF and they were wonderful. It was a great joy to help pass along knowledge to the next generation.</p>

          <p>“One of my sons graduated from CSUF in 1999. He is a huge sports fan. I enjoyed going with him to the College World Series twice. The last time was when they won and beat their former coach, Augie Garrido, who had moved to Texas. I have loved attending both baseball and basketball games at Cal State Fullerton. Our sports programs benefit CSUF by bringing positive recognition to our school.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2007 Honoree</span>
          <h2>Lucy Dunn ’76</h2>
          <span className='title'>CEO Emeritus, Orange County Business Council; former California Director of Housing and Community Development; former Chair, California Transportation Committee</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-right align-outside large'>
            <img src={lucyDunn} alt="Lucy Dunn" />
          </figure>
          </ScrollAnimation>
          
          <p>“My time at CSUF allowed me to explore different disciplines from math and physics to American studies to politics, with the flexibility that allowed me to work. I discovered the importance of being a ‘lifelong learner’ – always curious about the world and connecting the dots among different areas of interest to help people. Without realizing it, this freedom prepared me for a dynamic, changing and challenging career path — from law to corporate management, real estate entitlement, habitat protection, community engagement, media relations, nonprofit management — and in the public sector as former California Director of Housing and Community Development and a member of the California Transportation Commission.</p> 

          <p>“I stay connected to the university because it is cutting-edge in areas important to career advancement and personal growth. Every year, it just gets better from sciences, technology, mathematics, engineering, teaching and more. I’m a big fan of the university’s music and art programs, especially opera — continuing to produce outstanding talent from Deborah Voigt and Rodney Gilfry to new stars under program director Mark Goodrich. The importance of the arts in career development cannot be overstated. Art fosters creativity, problem-solving and teamwork — skills important to any career path that transcend and defend against future job losses due to the rise of artificial intelligence.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>1999 Honoree</span>
          <h2>Julie Greiner ’75</h2>
          <span className='title'>C-Suite Retail Executive, Director of several nonprofit organizations (retired)</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownRight">
          <figure className='align-left align-outside large'>
            <img src={julieGreiner} alt="Julie Greiner" />
          </figure>
          </ScrollAnimation>
            
          <p>“CSUF was essential in expanding my view of self, of the professional world and of the range of career choices that were open to me. I had a great professor of retailing who energized our classes with field trips and fascinating insider information on the industry that kindled my curiosity.</p>

          <p>“Even though I no longer live in California, I stay connected to my alma mater because it plays a very influential role in building and improving our communities through innovative programs. For many years I have been a supporter of the Guardian Scholars program, which serves emancipated foster youth. What could be better than fulfilling the dreams and aspirations of young people through access to a CSUF education?”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2003 Honoree</span>
          <h2>Bobby McDonald ’75</h2>
          <span className='title'>President and Executive Director, Black Chamber of Commerce of Orange County</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-left align-outside large'>
            <img src={bobbyMcdonald} alt="Bobby McDonald" />
          </figure>
          </ScrollAnimation>

          <p>“I never left Cal State Fullerton! When I finished my second tour of Vietnam, I knew my purpose was to get an education. I was always involved in student government, sports — I’ve known every president of the university, starting with William Langsdorf when I was a student. Turns out that Jewel Plummer Cobb went to the same school as my cousins in Chicago, so she and I worked together quite a bit. I got a chance to meet with elected officials, be a part of the statewide alumni program, even help in the selection of one of the CSU chancellors.</p>

          <p>“When I began with the Black Chamber of Commerce of Orange County, we started raising money to start a scholarship program for African American students. We also created a Black Chamber Education Fund to provide book scholarships.</p>

          <p>“I really enjoyed being with Milt Gordon as well. Turns out he came from the same town as my mother did in Louisiana…and every Christmas, I’d bring him a pot of my mother’s Louisiana gumbo! Under his leadership, we really started developing programming for Black History Month and I’ve always stayed active in supporting sports, veterans and community outreach. For me, Cal State Fullerton is where it all began. It’s home.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2014 Honoree</span>
          <h2>Darrell Jodoin ’85</h2>
          <span className='title'>Director of Global Development, Walt Disney Parks, Experiences and Products (retired)</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-right align-outside large'>
            <img src={darrellJodoin} alt="Darrell Jodoin" />
          </figure>
          </ScrollAnimation>

          <p>“CSUF prepared me by beginning with the fundamentals and then combining that with practical applications in the laboratory and real-world opportunities.</p>

          <p>“I stay connected with the university to help the institution and the individuals who provided me with the opportunity to transform my life. Now it’s my time to assist others.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2014 Honoree</span>
          <h2>Julie Miller-Phipps ’83</h2>
          <span className='title'>President, Kaiser Permanente Southern California and Hawaii Health Plan and Hospitals</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-right align-outside large'>
            <img src={julieMillerPhipps} alt="Julie Miller-Phipps" />
          </figure>
          </ScrollAnimation>

          <p>“My education at CSUF not only prepared me for the career that I had at the time, but also equipped me for what would become the entire trajectory of my career. I was one of the first in my family to attend a four-year university, and that accomplishment alone made me realize that I could achieve anything that I set my mind to. That mindset has served me well over the dozen roles that I’ve had in my career at Kaiser Permanente.</p>

          <p>“I felt so lucky to have found a campus that embraced who I was and where I needed to be at that time in my life. I was working full time while attending school full time, paying for my own education and starting a new family. Without the support that CSUF offered, I’m not sure I would have been able to navigate all those big life events at the same time.</p>

          <p>“I majored in sociology, with an emphasis in organizational sociology and group processes. I never imagined how much of what I learned in that degree program would continue to help me in my career, even years later. As a health care leader, a big part of my job is setting the tone for our organizational culture and understanding how best to direct a group of highly talented leaders. Those skills I learned in my organizational sociology classes have served me well over the years.</p>

          <p>“The leadership team at CSUF has always had a compelling vision for the school that makes me want to be involved. The goals that CSUF aspires to achieve speak to me — not only as a business leader in the community, but also as a community resident, one who is dedicated to making our area the best it can be.</p>

          <p>“Currently I serve on the President’s Advisory Council, where I get to see firsthand the opportunities and the challenges that face higher education in these unprecedented times. The thoughtfulness and visionary approach that President Fram Virjee and the CSUF leadership team apply to these realities is inspiring, and reaffirms my commitment to the university every day.”</p>

          <hr />
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className="date-tag">2017 Honoree</span>
          <h2>Anthony Rendon ’92, M.A. ’94</h2>
          <span className="title">70th Speaker of the California Assembly</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownRight">
          <figure className='align-left align-outside large'>
            <img src={anthonyRendon} alt="Anthony Rendon" />
          </figure>
          </ScrollAnimation>

          <p>“The most important thing that Cal State Fullerton provided me with is a strong foundation in critical thinking. Philosophy — all philosophy — is a really good background for politicians. Unfortunately, it’s missing in some politicians. Some people claim that liberal education is dead. I mean liberal in the classic educational sense of broad and integrated across subjects, as opposed to purely technical or vocational education. I think it’s needed now more than ever  — both for people in the political field and for the people who vote for and against them.</p>
            
          <p>“At Cal State Fullerton, there are facilities, like the Arboretum, that have special meaning to my family and me, but I also like what CSUF represents. I’m one of few state political figures to go through all levels of California’s public higher education. That’s community college, CSU and UC. It’s important to me to have that direct experience and I see CSU as the linchpin around which the system rotates.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className="date-tag">2006 Honoree</span>
          <h2>Marc Stein ’91</h2>
          <span className='title'>Independent NBA newsletter on Substack, The Stein Line; former Sports Reporter for The New York Times, ESPN, The Washington Post, etc.</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownRight">
          <figure className='align-left align-outside large'>
            <img src={marcStein} alt="Marc Stein" />
          </figure>
          </ScrollAnimation>

          <p>“My four years at CSUF were crucial to my career development. The Daily Titan was good as it gets in terms of college publications. During my era, DT staff members routinely worked part time for major metropolitan daily newspapers in addition to our studies and Daily Titan duties. I’m convinced that being in such a fiercely competitive environment helped get me ready for the competitive nature of covering top-level pro sports.</p>

          <p>“I stay connected for two reasons: the strength of our journalism program, but I must confess that Titan Athletics plays a huge role, too. I became a Cal State Fullerton sports fan when I was still in high school, watching Leon Wood lead the Titans to an upset of UNLV in 1983 when the Runnin’ Rebels were ranked No. 1 in the country. My Titan fandom only intensified when I attended the school from 1987-91. I haven’t lived in California since the mid-1990s because of work obligations, but blue-and-orange fever has never left me.”</p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2012 Honoree</span>
          <h2>Jeffrey Van Harte ’80</h2>
          <span className='title'>Chief Investment Officer, Jackson Square Partners</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInDownLeft">
          <figure className='align-right align-outside large'>
            <img src={jeffreyVanHarte} alt="Jeffrey Van Harte" />
          </figure>
          </ScrollAnimation>

          <p>“The undergraduate education I received at the business school immediately made me competitive with MBAs that I worked with on my first job, so I am forever grateful that I chose CSUF and for the role it played in jump-starting my career. </p>

          <p>“I feel a strong loyalty to CSUF and the role it occupies in public higher education. It is truly the people’s university and touches multiple aspects of Orange County and SoCal communities.” </p>

          <hr />
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <span className='date-tag'>2011 Honoree</span>
          <h2>Vicki Vasques ’76</h2>
          <span className='title'>Owner and Chairwoman of Tribal Tech LLC and Cowan & Associates (management and technical services company)</span>

          <ScrollAnimation animateOnce={true} animateIn="rotateInUpLeft">
          <figure className='align-right align-outside large'>
            <img src={vickiVasques} alt="Vicki Vasques" />
          </figure>
          </ScrollAnimation>

          <p>“My education at CSUF as well as my involvement in its Native American program, gave me the foundation to pursue my passion — education and native affairs. I gained the knowledge and confidence to move across the country to pursue a career with the U.S. Department of Education in Washington, D.C. I truly came to the nation’s capital with all intentions of staying for one year, then returning to Southern California. However, one could say I caught the ‘Potomac Fever,’ because here I am 40 years later, the proud owner of a successful small business that serves federal, tribal, state, local and private entities.</p>

          <p>“I am very fortunate to be able to give back through the RMP Foundation, which I founded in memory of my father, who served as tribal chairman of our tribe, the San Pasqual Band of Mission Indians in San Diego County. He started the first education program on the reservation (long before gaming and casinos). He taught me the importance of giving back and investing in your community and what better community to invest in than the Titan community? It Takes a Titan!” <span className='end-mark' /></p>
          </ScrollAnimation>

          <p className="learn-more">Support Cal State Fullerton<br /><a href="https://campaign.fullerton.edu" target="_blank" rel="noreferrer">campaign.fullerton.edu</a></p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 